
import { Link } from "react-router-dom";
import AppLogo from './assets/images/logobig.png';
import imgSlider1 from './assets/images/slider/1.png';
import imgSlider2 from './assets/images/slider/2.png';
import imgSlider3 from './assets/images/slider/3.png';
import imgSlider4 from './assets/images/slider/4.png';
import imgSlider5 from './assets/images/slider/5.png';
import imgSlider6 from './assets/images/slider/6.png';
import imgSlider7 from './assets/images/slider/7.png';
import imgSlider8 from './assets/images/slider/8.png';
import assetsBackground from './assets/images/assetsbackground.png';
import Carousel from 'react-bootstrap/Carousel';
import "./css/style.css";
import "./css/plugins.css";
require('dotenv').config();
const Minter = (props) => {
  return (
    <div className="body">
      <div class="metaportal_fn_mobnav">
        <div class="mob_mid">
        <div class="wallet">
              <ul class="top-link-menu">
                <li>
                  <a href="https://twitter.com/UBonesSociety"><svg width="26" height="26" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8.77905 4.63903L8.74003 3.99023C8.62298 2.30867 9.65045 0.772755 11.2762 0.176925C11.8745 -0.0349253 12.8889 -0.0614071 13.5522 0.123963C13.8124 0.203406 14.3066 0.468219 14.6578 0.706551L15.2951 1.14349L15.9974 0.918401C16.3876 0.799235 16.9078 0.600626 17.1419 0.468219C17.363 0.349053 17.5581 0.28285 17.5581 0.322572C17.5581 0.547663 17.0769 1.31562 16.6737 1.73932C16.1274 2.33515 16.2835 2.38811 17.389 1.99089C18.0523 1.7658 18.0653 1.7658 17.9353 2.01738C17.8572 2.14978 17.454 2.61321 17.0248 3.03691C16.2965 3.76514 16.2575 3.84459 16.2575 4.45366C16.2575 5.39374 15.8153 7.35336 15.3731 8.42585C14.5537 10.4384 12.7979 12.5172 11.0421 13.5632C8.57095 15.0329 5.28043 15.4037 2.51016 14.543C1.58673 14.2517 0 13.5103 0 13.3779C0 13.3381 0.481222 13.2852 1.06649 13.2719C2.28906 13.2455 3.51162 12.9012 4.5521 12.2921L5.25442 11.8684L4.44805 11.5904C3.30352 11.1932 2.27605 10.2795 2.01593 9.4189C1.93789 9.14085 1.9639 9.12761 2.69224 9.12761L3.44659 9.11437L2.80929 8.80984C2.05495 8.42586 1.36563 7.77706 1.02747 7.11503C0.78036 6.63837 0.468216 5.43347 0.559258 5.34078C0.58527 5.30106 0.858396 5.3805 1.17054 5.48643C2.06795 5.81745 2.18501 5.738 1.66477 5.18189C0.689318 4.1756 0.39018 2.67941 0.858395 1.26266L1.0795 0.627107L1.93789 1.48775C3.6937 3.22228 5.76166 4.25505 8.12875 4.55958L8.77905 4.63903Z" fill="#fff"></path></svg></a>
                </li>
                <li>
                  <a href="https://opensea.io/collection/unearthed-bones-society"><svg width="26" height="26" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.71086 3.33457C5.8823 3.72476 6.02419 4.06173 6.04192 4.10311C6.53342 5.19561 6.83913 6.36249 6.94643 7.55564C6.96435 8.40747 6.72762 9.24527 6.26657 9.96177C6.04783 10.3047 5.81136 10.6357 5.81136 10.6357H1.04049C1.04049 10.6357 4.45163 5.30913 5.71086 3.33457ZM20.3664 12.829L19.9821 13.0123C19.894 13.0731 19.7986 13.1227 19.6983 13.1601C18.9489 13.4787 18.3255 14.0356 17.9248 14.7445C17.4694 15.5606 16.9473 16.3377 16.364 17.0678C16.1077 17.425 15.7718 17.7177 15.383 17.9229C14.9942 18.1281 14.563 18.2401 14.1234 18.2502H5.00143C2.32927 18.2502 0.0650304 15.6844 0 13.0123V12.5275H5.10193V12.9413C5.12791 13.3893 5.32789 13.8094 5.65922 14.112C5.99055 14.4146 6.427 14.5758 6.87549 14.5612H9.09244V12.5689H6.7277C6.69222 12.492 6.7277 12.3679 6.85184 12.1964C7.24203 11.6053 7.60856 10.949 7.96919 10.3106C8.84414 8.76757 8.74955 7.15954 8.30616 5.52196C7.87937 4.04384 7.28429 2.61961 6.5326 1.27725C6.5326 1.27725 7.82139 1.5551 9.10426 1.9039V0.916623C9.12355 0.667211 9.23624 0.434262 9.41982 0.26433C9.6034 0.0943984 9.84434 0 10.0945 0C10.3447 0 10.5856 0.0943984 10.7692 0.26433C10.9528 0.434262 11.0655 0.667211 11.0847 0.916623V2.56012L11.3389 2.68427C12.2402 3.37393 13.0718 4.1501 13.8219 5.00172C14.1491 5.42112 14.4455 5.8637 14.7087 6.32597C15.0803 6.92048 15.2674 7.61177 15.2464 8.31254C15.2253 9.01332 14.9971 9.69213 14.5905 10.2633C14.0596 11.0539 13.443 11.7835 12.7519 12.4388C12.7127 12.4792 12.6691 12.5149 12.6218 12.5452H11.0847V14.5021H12.8228C13.3643 14.4595 13.8712 14.2197 14.2476 13.8281C14.4722 13.5857 15.0279 12.8586 15.0279 12.8586L20.3486 11.4102L20.3664 12.829Z" fill="#fff"></path></svg></a>
                </li>
              </ul>
            </div>
          <div class="logo">
            <Link to="/home"><img src={AppLogo} alt="" /></Link>
          </div>
        </div>
      </div>
      <div id="header">
        <div class="header">
          <div class="header_in">
            <div class="trigger_logo">
              <div class="trigger2">
                <span></span>
              </div>
              <div class="logo">
                <Link to="/home"><img src={AppLogo} alt="" /></Link>
              </div>
            </div>
            <div class="wallet">
              <ul class="top-link-menu">
                <li>
                  <a href="https://twitter.com/UBonesSociety"><svg width="26" height="26" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8.77905 4.63903L8.74003 3.99023C8.62298 2.30867 9.65045 0.772755 11.2762 0.176925C11.8745 -0.0349253 12.8889 -0.0614071 13.5522 0.123963C13.8124 0.203406 14.3066 0.468219 14.6578 0.706551L15.2951 1.14349L15.9974 0.918401C16.3876 0.799235 16.9078 0.600626 17.1419 0.468219C17.363 0.349053 17.5581 0.28285 17.5581 0.322572C17.5581 0.547663 17.0769 1.31562 16.6737 1.73932C16.1274 2.33515 16.2835 2.38811 17.389 1.99089C18.0523 1.7658 18.0653 1.7658 17.9353 2.01738C17.8572 2.14978 17.454 2.61321 17.0248 3.03691C16.2965 3.76514 16.2575 3.84459 16.2575 4.45366C16.2575 5.39374 15.8153 7.35336 15.3731 8.42585C14.5537 10.4384 12.7979 12.5172 11.0421 13.5632C8.57095 15.0329 5.28043 15.4037 2.51016 14.543C1.58673 14.2517 0 13.5103 0 13.3779C0 13.3381 0.481222 13.2852 1.06649 13.2719C2.28906 13.2455 3.51162 12.9012 4.5521 12.2921L5.25442 11.8684L4.44805 11.5904C3.30352 11.1932 2.27605 10.2795 2.01593 9.4189C1.93789 9.14085 1.9639 9.12761 2.69224 9.12761L3.44659 9.11437L2.80929 8.80984C2.05495 8.42586 1.36563 7.77706 1.02747 7.11503C0.78036 6.63837 0.468216 5.43347 0.559258 5.34078C0.58527 5.30106 0.858396 5.3805 1.17054 5.48643C2.06795 5.81745 2.18501 5.738 1.66477 5.18189C0.689318 4.1756 0.39018 2.67941 0.858395 1.26266L1.0795 0.627107L1.93789 1.48775C3.6937 3.22228 5.76166 4.25505 8.12875 4.55958L8.77905 4.63903Z" fill="#fff"></path></svg></a>
                </li>
                <li>
                  <a href="https://opensea.io/collection/unearthed-bones-society"><svg width="26" height="26" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.71086 3.33457C5.8823 3.72476 6.02419 4.06173 6.04192 4.10311C6.53342 5.19561 6.83913 6.36249 6.94643 7.55564C6.96435 8.40747 6.72762 9.24527 6.26657 9.96177C6.04783 10.3047 5.81136 10.6357 5.81136 10.6357H1.04049C1.04049 10.6357 4.45163 5.30913 5.71086 3.33457ZM20.3664 12.829L19.9821 13.0123C19.894 13.0731 19.7986 13.1227 19.6983 13.1601C18.9489 13.4787 18.3255 14.0356 17.9248 14.7445C17.4694 15.5606 16.9473 16.3377 16.364 17.0678C16.1077 17.425 15.7718 17.7177 15.383 17.9229C14.9942 18.1281 14.563 18.2401 14.1234 18.2502H5.00143C2.32927 18.2502 0.0650304 15.6844 0 13.0123V12.5275H5.10193V12.9413C5.12791 13.3893 5.32789 13.8094 5.65922 14.112C5.99055 14.4146 6.427 14.5758 6.87549 14.5612H9.09244V12.5689H6.7277C6.69222 12.492 6.7277 12.3679 6.85184 12.1964C7.24203 11.6053 7.60856 10.949 7.96919 10.3106C8.84414 8.76757 8.74955 7.15954 8.30616 5.52196C7.87937 4.04384 7.28429 2.61961 6.5326 1.27725C6.5326 1.27725 7.82139 1.5551 9.10426 1.9039V0.916623C9.12355 0.667211 9.23624 0.434262 9.41982 0.26433C9.6034 0.0943984 9.84434 0 10.0945 0C10.3447 0 10.5856 0.0943984 10.7692 0.26433C10.9528 0.434262 11.0655 0.667211 11.0847 0.916623V2.56012L11.3389 2.68427C12.2402 3.37393 13.0718 4.1501 13.8219 5.00172C14.1491 5.42112 14.4455 5.8637 14.7087 6.32597C15.0803 6.92048 15.2674 7.61177 15.2464 8.31254C15.2253 9.01332 14.9971 9.69213 14.5905 10.2633C14.0596 11.0539 13.443 11.7835 12.7519 12.4388C12.7127 12.4792 12.6691 12.5149 12.6218 12.5452H11.0847V14.5021H12.8228C13.3643 14.4595 13.8712 14.2197 14.2476 13.8281C14.4722 13.5857 15.0279 12.8586 15.0279 12.8586L20.3486 11.4102L20.3664 12.829Z" fill="#fff"></path></svg></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="metaportal_fn_main">
        <div class="metaportal_fn_content">
          <div id="home">
            <div class="container">
              <h3 class="fn__maintitle big" data-text="Unearthed Bones Society" data-align="center">Unearthed Bones Society</h3>
             
                <div class="slider-home-desc">
                  <div class="fn_cs_desc">
                  The Unearthed Bones Society is a group of uprooted souls who live peacefully on the other side. We believe in the power of play and imagination, a we are committed to helping you unlock your inner child.
                  </div>
                  <div class="mint-button">
                  <a target="_blank" href={"https://mint.fun/0x0827f7fa9df73f76a33d57d1ab636cd92f393bd8"}>
                    Mint Now!
                  </a>
                  </div>
                </div>
                <div class="slider-container">
                <div class="slider-body">
                  <Carousel fade>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={imgSlider1} height={350}
                        alt=""
                      />
                      <Carousel.Caption>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        height={350}
                        src={imgSlider2}
                        alt=""
                      />

                      <Carousel.Caption>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        height={350}
                        src={imgSlider3}
                        alt=""
                      />

                      <Carousel.Caption>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        height={350}
                        src={imgSlider4}
                        alt=""
                      />

                      <Carousel.Caption>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        height={350}
                        src={imgSlider5}
                        alt=""
                      />

                      <Carousel.Caption>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        height={350}
                        src={imgSlider6}
                        alt=""
                      />

                      <Carousel.Caption>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        height={350}
                        src={imgSlider7}
                        alt=""
                      />

                      <Carousel.Caption>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        height={350}
                        src={imgSlider8}
                        alt=""
                      />

                      <Carousel.Caption>
                      </Carousel.Caption>
                    </Carousel.Item>

                  </Carousel>

                </div>
              </div>
            </div>
          </div>
          <div class="fn_cs_section_divider">
            <div class="divider">
              <span class="short"></span>
              <span class="long"></span>
              <span class="short"></span>
            </div>
          </div>
          <section id="about">
            {/* <!-- About Shortcode --> */}
            <h3 class="fn__maintitle big" data-text="You own your Unearthed Bones Society" >You own your Unearthed Bones Society</h3>
            <div class="slider-container">
              <div class="slider-home-desc">
                <div class="fn_cs_desc">
                  <br />
                  Our commercial license allows you to use, distribute, reproduce, display, perform, modify and create derivative works of the Unearthed Bones Society acquired for personal and commercial uses.
                  Be creative !
                </div>
              </div>
              <div class="slider-body">
                <img src={assetsBackground}
                  className="d-block w-100"
                  height={350} alt=""
                />
              </div>
            </div>
          </section>
          <div class="fn_cs_section_divider">
            <div class="divider">
              <span class="short"></span>
              <span class="long"></span>
              <span class="short"></span>
            </div>
          </div>
          <section id="news">
            <div class="container">

              <h3 class="fn__maintitle big" data-text="FAQs" data-align="center">FAQs</h3>

              {/* <!-- News Shotcode --> */}
              <div class="fn_cs_news">
                <div class="news_part">
                  <div class="left_items">
                    <div class="blog__item">
                      <div class="title">
                        <h3>What Are The NFTs?</h3>
                      </div>
                      <div class="read_more">
                        <p>
                          A non-fungible token(NFT) is a non-interchangeable unit of data stored on a
                          blockchain, a form of digital ledger, that can be sold and traded. Types of
                          NFT data units may be associated with digital files such as photos, videos,
                          and audio.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="right_items">
                    <div class="blog__item">
                      <div class="title">
                        <h3>How Can We Buy Your NFTs?</h3>
                      </div>
                      <div class="read_more">
                        <p>We will answer this question when we're ready to start minting</p>
                      </div>
                    </div>
                    <div class="blog__item">
                      <div class="title">
                        <h3>Where Can I Get More Information?</h3>
                      </div>
                      <div class="read_more">
                        <p>This website will be updated weekly, but should you have any further
                          questions, please DM us on Twitter or get in touch with our Team on Discord.
                        </p>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
              {/* <!-- !News Shotcode --> */}

            </div>
          </section>

          <div class="fn_cs_section_divider">
            <div class="divider">
              <span class="short"></span>
              <span class="long"></span>
              <span class="short"></span>
            </div>
          </div>

          {/* <!-- Footer --> */}
          <footer id="footer">
            <div class="container">
              <div class="footer">
                <div class="left_part">
                  <p class="copyrightText"></p>
                </div>
                <div class="right_part">
                  <ul>
                    <li><Link to="/terms">Terms &amp; Conditions</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </footer>
          {/* <!-- !Footer --> */}

        </div>
      </div>
    </div>

  );
};

export default Minter;
